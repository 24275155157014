<template>
    <v-row>
        <v-col>
            <v-card style="height:275px;">
                <v-card-title class="d-flex justify-center">
                    <span class="text-body-2">INVESTING</span>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <tr>
                            <td>
                                CNY/RUB
                            </td>
                            <td>
                                
                            </td>
                            <td  class="text-right">
                                {{ CnyRub.range }} 
                            </td>
                            <td  class="text-right">
                                {{ CnyRub.cur }} 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                USD/CNY
                            </td>
                            <td>
                                
                            </td>
                            <td  class="text-right">
                                {{ UsdCny.range }} 
                            </td>
                            <td  class="text-right">
                                {{ UsdCny.cur }} 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                USD/RUB
                            </td>
                            <td>
                                
                            </td>
                            <td  class="text-right">
                                {{ UsdRub.range }} 
                            </td>
                            <td  class="text-right">
                                {{ UsdRub.cur }} 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                EUR/RUB
                            </td>
                            <td>
                                
                            </td>
                            <td  class="text-right">
                                {{ EurRub.range }} 
                            </td>
                            <td  class="text-right">
                                {{ EurRub.cur }} 
                            </td>
                        </tr>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'

export default {
    name: 'InvestingCom',

    data: () => ({
        CnyRub: [],
        UsdCny: [],
        UsdRub: [],
        EurRub: [],
        interval: null
    }),
    created() {
        let self = this;
        this.interval = setInterval(() => {
            axios.get('http://kutakbaev.ru:8080/investing').then(res => {
                self.CnyRub = res.data.cnyrub;
                self.UsdCny = res.data.usdcny;
                self.UsdRub = res.data.usdrub;
                self.EurRub = res.data.eurrub;
                console.log(self)
            })
        }, 60000);
    },
    beforeDestroy() {
        // prevent memory leak
        clearInterval(this.interval)
    },

}
</script>